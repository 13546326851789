/* Features.css */

.feature-card {
    background-color: #06415C; /* Set card background to dark blue */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
    color: #06415C; /* Text color inside the card */
  }
  
  .feature-card:hover {
    transform: scale(1.03); /* Slightly enlarges the card */
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow on hover */
  }
  
  .icon-container {
    transition: background-color 0.3s ease;
    background-color: #F39E1E; /* Set icon container background to orange */
  }
  
  .icon-container:hover {
    background-color: #eaeaea; /* Optional: Change icon background color on hover */
  }
  
  .icon-style {
    color: #F39E1E; /* Dark blue color for icons */
  }
  
  .small-text {
    font-size: 0.85rem; /* Reduce the font size */
    margin: 0; /* Remove extra margins */
    padding: 0; /* Remove extra padding */
  }
  
  .p-2 {
    padding: 0.5rem !important; /* Adjust padding to minimize space */
  }
  
  .mb-3 {
    margin-bottom: 1rem !important; /* Adjust margin to minimize space */
  }
  