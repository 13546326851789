.recent-products-section {
    padding: 2rem 0; /* Adds padding around the section */
  }
  
  .section-heading h2 span {
    color: #F39E1E; /* Optional: style for the 'Products' text */
  }
  
  .section-heading h2 {
    color: #06415C; /* Optional: style for the 'Products' text */
  }
  .product-row {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem; /* Space between columns */
  }
  
  .product-img {
    width: 100%; /* Ensure the image takes up full width of its container */
    height: auto; /* Maintain aspect ratio */
    border: 2px solid #ddd; /* Border color and width */
    border-radius: 8px; /* Rounded corners */
    object-fit: cover; /* Ensures images cover the area without distortion */
    margin-bottom: 1rem; /* Adds space below each image */
  }
  