.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #1a5adb;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #06415C;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.video{
  border:none;
  overflow:"hidden";
  
               
}

.userPhoto{
  /* margin-top: 20px; */
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.news-card{
  height: 300px;
}

.img-size{
  border-radius: 50%;
  margin-top: 15px;
  width: 120px !important;
  height: 120px;
}

.colored-text {
  color: #e76f51;
}
.colored-name {
  color: #eb0f5d;
}

.news-card{
  height: 300px;
}

.title-1{
  color: #fa9a01;
}

.container-full-height{
  border: 1px solid red;
}


/* Custom Sidebar Styles */
.custom-sidebar .offcanvas-body {
  background-color: #06415C;
  color: #fff;
  width: 200px; /* Adjust the width as needed */
}

.custom-sidebar .list-group-item {
  background-color :#06415C;
  color: #fff;
  border: none;
  transition: background-color 0.3s;
}

.custom-sidebar .list-group-item:hover {
  background-color: #F39E1E;/* Change this color on hover */
  cursor: pointer;
}

/* Sidebar Item Styling for Desktop */
.modern-sidebar .list-group-item {
  /* background-color: #06415C; */
  color:  #06415C;
  border: 0.5px solid #F39E1E;;
  transition: background-color 0.3s;
  
}

.modern-sidebar .list-group-item:hover {
  background-color: #F39E1E;/* Change this color on hover */
  cursor: pointer;
}

/* Ensure the sidebar has consistent styling on different screen sizes */
.modern-sidebar {
  width: 200px; /* Adjust the width as needed */
}


