.footer-container {
    background-color: #06415C !important; /* Override the Bootstrap dark background if needed */
  }
  
  h5 {
    border-bottom: 1px solid #f1c40f;
    padding-bottom: 5px;
  }
  
  .footer-link {
    text-decoration: none;
    color: #ffffff;
    transition: color 0.3s ease;
  }
  
  .footer-link:hover {
    color: #f1c40f; /* Highlight color on hover */
  }
  
  .social-icon {
    margin-right: 10px;
    color: #ffffff;
    font-size: 1.5rem; /* Adjust icon size */
    transition: color 0.3s ease;
  }
  
  .social-icon:hover {
    color: #F39E1E; /* Change color on hover */
  }
  
  .whatsapp-chat {
    color: #25D366; /* WhatsApp green */
  }
  
  .whatsapp-chat:hover {
    color: #128C7E; /* Darker green on hover */
  }
  
  /* Floating WhatsApp Live Chat Button */
  .whatsapp-live-chat {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25D366;
    color: white;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 10px #06415C;;
    transition: background-color 0.3s ease, transform 0.3s ease;
    z-index: 1000;
  }
  
  .whatsapp-live-chat:hover {
    background-color: #128C7E;
    transform: scale(1.1);
  }
  

  