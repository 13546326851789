
   .header {
    background-color: #06415C;
    color: #F39E1E;
 
    padding-left: 10px;
    padding-right: 25px;
    padding-top: 20px;
    padding-bottom: 10px;
    
  }
  
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: right;
    padding-bottom: 5px; /* Reduced padding */
    border-bottom: 1px solid #fff;
    margin-left: 40px;
  }
  
  .logo img {
    height: 50px; /* Reduced size */
  }
  
  .search-bar {
    display: flex;
    align-items: center;
  }
  
  .search-bar input {
    padding: 5px;
    width: 250px; /* Reduced width */
    border: none;
    border-radius: 3px; /* Reduced border-radius */
    font-size: 14px; /* Reduced font size */
  }
  
  .search-bar button {
    background-color: #F39E1E;
    border: none;
    padding: 4px 6px; /* Reduced padding */
    color: white;
    margin-left: 5px;
    border-radius: 3px; /* Reduced border-radius */
    cursor: pointer;
  }
  
  .contact-info, .user-account, .cart-info {
    display: flex;
    align-items: center;
    gap: 2px; /* Reduced gap */
  }
  
  .icon {
    font-size: 14px; /* Reduced font size */
  }
  .navbar {
    display: flex;
    justify-content: center; /* Center the entire navbar content */
    align-items: center; /* Center vertically */
    background-color: #06415C;
    padding: 5px 0; /* Reduced padding */
  }
  
  .navbar ul {
    display: flex;
    list-style-type: none;
    gap: 14px; /* Reduced gap */
    margin: 0 auto; /* Use auto margins to center the list */
    padding: 0; /* Remove default padding */
  }
  
  .navbar li {
    display: flex;
    align-items: center; /* Center content within list items */
  }
  
  .navbar a {
    color: white;
    text-decoration: none;
    padding: 5px 10px; /* Reduced padding */
    font-size: 14px; /* Reduced font size */
    transition: background-color 0.3s;
  }
  
  
  .navbar a:hover {
    background-color: #F39E1E;
    border-radius: 3px; /* Reduced border-radius */
  }
  
  .enterprise-solutions {
    background-color: #F39E1E;
    padding: 5px 10px; /* Reduced padding */
    border-radius: 3px; /* Reduced border-radius */
    color: white;
  }
  
  .enterprise-solutions:hover {
    background-color: #cc5200;
  }
  

  